import { graphql, navigate } from "gatsby"
import React, { Fragment, useEffect,useState } from "react"
import "../scss/global.scss"
import loadable from "@loadable/component";
import BannerLanding from "../components/BannerLanding/BannerLanding"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/seo"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import useDeviceMedia from "../hooks/useDeviceMedia"
import useCompanyInfo from "../hooks/useCompanyInfo"
import MobileContact from "../components/MobileContact/MobileContact"
import HomeStructuredData from "../components/Seo/HomeStructuredData"

// const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
// const FormContentComponent = loadable(() => import("../components/FormContentComponent/FormContentComponent"));
// const UpComingRoadShows = loadable(() => import("../components/UpComingRoadShow/UpComingRoadShows"));
// const NewsSlider = loadable(() => import("../components/NewsSlider/NewsSlider"));
// const ContentWIthStatsModule = loadable(() => import("../components/ContentWIthStatsModule/ContentWIthStatsModule"));
// const AreaguideSlider = loadable(() => import("../components/AreaguideSlider/AreaguideSlider"));

import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties"
import FormContentComponent from "../components/FormContentComponent/FormContentComponent"
import UpComingRoadShows from "../components/UpComingRoadShow/UpComingRoadShows"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import ContentWIthStatsModule from "../components/ContentWIthStatsModule/ContentWIthStatsModule"
import AreaguideSlider from "../components/AreaguideSlider/AreaguideSlider"


const DefaultTemplate = props => {
  const { data, location } = props

  const GQLPage = data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  const locationState = location.state
  const pageLayout = GQLPage.layout
  const bannerData = GQLPage.banner_section

  if (!GQLPage.publish) navigate("/404")

  const {isLargeScreen,isMobile}=useDeviceMedia()
  const { phone } = useCompanyInfo()

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])


  return (
    <>
      <HomeStructuredData />
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
      />
      <Layout pageLayout={pageLayout} popularSearch={GQLPage?.Popular_Search} GQLPage={GQLPage}>
      { !isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }

        {
          isMobile && renderComponent &&
          <MobileContact/>
        }

        {bannerData.banner_image && 
        <BannerLanding bannerData={bannerData} GQLPage={GQLPage}/>
        }

        {GQLModules.map(module => {
          return (
            <Fragment key={module.id}>
              {module.__typename ===
                "GLSTRAPI_ComponentModulesFeaturedProperties" && renderComponent &&(
                <FeaturedProperties module={module} />
              )}

              {module.__typename ===
                "GLSTRAPI_ComponentModulesContentWithStats" && (
                <ContentWIthStatsModule module={module} />
              )}

              {module.__typename ===
                "GLSTRAPI_ComponentComponentsSelectCollection" &&
                module.choose_collection === "areaguide_slider" && renderComponent &&(
                  <AreaguideSlider module={module} />
                )}

              {module.__typename ===
                "GLSTRAPI_ComponentComponentsSelectCollection" &&
                module.choose_collection === "news_slider" && renderComponent && (
                  <NewsSlider module={module} />
                )}

              {module.__typename ===
                "GLSTRAPI_ComponentComponentsSelectModules" &&
                module.select_module === "upcoming_roadshows" && (!isMobile || renderComponent) && (
                  <UpComingRoadShows isModule={true}/>
                )}

              {module.__typename ===
                "GLSTRAPI_ComponentModulesGuideDownloadModule" && (!isMobile || renderComponent) && (
                <FormContentComponent module={module} />
              )}
            </Fragment>
          )
        })}
      </Layout>
    </>
  )
}

export default DefaultTemplate

export const HomepageQuery = graphql`
  query GetHomePage($id: ID!) {
    glstrapi {
      page(id: $id) {
        seo_meta {
          ...SeoMeta
        }
        page_class
        layout
        publish
        alias
        choose_form
        content
        id
        Popular_Search
        title
        page_class
        publish
        banner_section {
          mobile_banner_image{
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          banner_image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          banner_video {
            url
          }
          mobile_banner_video {
            url
          }
          content
          id
          show_reviews
          show_search
          title
          title2
          button_cta_1 {
            ...buttonCtaData
          }
          button_cta_2 {
            ...buttonCtaData
          }
        }
        select_contact {
          ...team
        }
        add_components {
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            ...selectCollectionData
          }

          ... on GLSTRAPI_ComponentComponentsSelectModules {
            __typename
            id
            select_module
          }

          ... on GLSTRAPI_ComponentModulesGuideDownloadModule {
            ...GuideDownloadModule
          }

          ... on GLSTRAPI_ComponentModulesContentWithStats {
            __typename
            id
            title
            content_stat_theme
            stats {
              stat
              id
              description
            }
            select_collection {
              ...selectCollectionData
            }
            description
            cta {
              ...buttonCtaData
            }
          }

          ... on GLSTRAPI_ComponentModulesFeaturedProperties {
            ...FeaturedProperties
          }
        }
      }
    }
  }
`
